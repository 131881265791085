// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* HTML: <div class=\"loader\"></div> */\n.updatingLoader_loader___sb79 {\n  width: 30px;\n  aspect-ratio: 1;\n  border-radius: 50%;\n  background: radial-gradient(farthest-side, #ffc701 94%, #0000) top/8px 8px\n      no-repeat,\n    conic-gradient(#0000 30%, #ffc701);\n  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);\n  animation: updatingLoader_l13__2omIR 1s infinite linear;\n}\n@keyframes updatingLoader_l13__2omIR {\n  100% {\n    transform: rotate(1turn);\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/loader/updatingLoader.module.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB;;sCAEoC;EACpC,4EAA4E;EAC5E,uDAAiC;AACnC;AACA;EACE;IACE,wBAAwB;EAC1B;AACF","sourcesContent":["/* HTML: <div class=\"loader\"></div> */\n.loader {\n  width: 30px;\n  aspect-ratio: 1;\n  border-radius: 50%;\n  background: radial-gradient(farthest-side, #ffc701 94%, #0000) top/8px 8px\n      no-repeat,\n    conic-gradient(#0000 30%, #ffc701);\n  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);\n  animation: l13 1s infinite linear;\n}\n@keyframes l13 {\n  100% {\n    transform: rotate(1turn);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "updatingLoader_loader___sb79",
	"l13": "updatingLoader_l13__2omIR"
};
export default ___CSS_LOADER_EXPORT___;

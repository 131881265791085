// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loader2_parent__1pd0z {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 50%;\n  width: 78%;\n\n  position: fixed;\n  /* border: 2px solid red; */\n}\n\n.loader2_child__q9-p_ {\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.loader2_loaderImage__2t1GU {\n  width: 50px !important;\n  height: 50px !important;\n}\n\n.loader2_noDataImage__Pl7Z4 {\n  width: 90%;\n  height: 90%;\n}\n", "",{"version":3,"sources":["webpack://src/components/loader/loader2.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,UAAU;;EAEV,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,WAAW;AACb","sourcesContent":[".parent {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 50%;\n  width: 78%;\n\n  position: fixed;\n  /* border: 2px solid red; */\n}\n\n.child {\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.loaderImage {\n  width: 50px !important;\n  height: 50px !important;\n}\n\n.noDataImage {\n  width: 90%;\n  height: 90%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parent": "loader2_parent__1pd0z",
	"child": "loader2_child__q9-p_",
	"loaderImage": "loader2_loaderImage__2t1GU",
	"noDataImage": "loader2_noDataImage__Pl7Z4"
};
export default ___CSS_LOADER_EXPORT___;

export const createEntityDemoData =    {

    catId: "c5",
    name: "DANCE ALL Studio ( DA - International dance academy )",
    description: "Such a happening place to be with your date, friends, your gals or your gang!! The food is amazing, the dance place is so beautiful and owners so friendly and welcoming! Masala chai is must try here. Fun and happy vibes :))))",
    address: "Ground floor, Plot no-1, Compound- 1 Marol cooperative industrial Estate, Marol Maroshi Rd, Marol, Andheri East, Mumbai, Maharashtra 400059",
    area: 500,
    city: "Mumbai",
    state: "Maharasatra",
    country: "IN",
    pincode: "400064",
    amenities: [
      {
        id: "0",
        name: "Wifi"
      },
      {
        id: "1",
        name: "AC"
      },
      {
        id: "2",
        name: "DJ"
      },
      {
        id: "3",
        name: "Piano"
      }
    ],
    facilities: [
      {
        id: "0",
        name: "AC"
      },
      {
        id: "1",
        name: "Parking"
      },
      {
        id: "2",
        name: "Wifi"
      }
    ],
    photos: [
      "https://images.stockcake.com/public/2/a/b/2ab9d255-8a1c-4283-a289-cb81e8f905d5_large/spacious-dance-studio-stockcake.jpg",
      "https://i.pinimg.com/originals/65/25/53/652553dd5d9776948f1ab35dfbc569e3.jpg",
      "https://i.pinimg.com/originals/56/51/90/5651900d89db463081df36b5033dc5cf.jpg"
    ],
    approval: "Instant",
    isActive: 1,
    location: {
      type: "Point",
      coordinates: [
        72.8834064,
        19.1116682
      ]
    },
    minPrice: {
      price: 4000,
      basePrice: 5000,
      discountPercentage: 20
    },
    reviews: [],
    teamDetails: [
      {
        id: 1,
        imgUrl: "http://192.168.1.7:8080/api/v2/download//download/1735889339763dance.jpg",
        name: "sonu",
        designation: "dj"
      }
    ],
    capacity: "7",
    listingDetails: [
      {
        listingId: 1,
        listingName: "r1",
        listingPhotos: [
          "https://media.istockphoto.com/id/157424296/photo/ballet-studio-old-building.jpg?s=612x612&w=0&k=20&c=JxWUEvyACRPtuAITSMdNuK9Qnbf0f49EMelz9rxI9l0=",
          "https://t3.ftcdn.net/jpg/10/22/25/44/360_F_1022254445_Mq9f8UvgjPezUKkO5hR8Y2vch4Gqw65a.jpg"
        ],
        area: 500,
        seats: 0,
        sessionHours: 0,
        price: 4000,
        basePrice: 5000,
        discountPercentage: 20,
        amenities: [
          {
            id: "0",
            name: "Wifi"
          },
          {
            id: "1",
            name: "AC"
          },
          {
            id: "2",
            name: "DJ"
          },
          {
            id: "3",
            name: "Piano"
          }
        ],
        bookingTypes: [
          "b1"
        ],
        generalTime: {
          startTime: "00:00",
          endTime: "23:59"
        },
        bookingDays: [
          {
            day: "monday",
            startTime: "00:00",
            endTime: "23:59"
          },
          {
            day: "tuesday",
            startTime: "00:00",
            endTime: "23:59"
          },
          {
            day: "wednesday",
            startTime: "00:00",
            endTime: "23:59"
          },
          {
            day: "thursday",
            startTime: "00:00",
            endTime: "23:59"
          },
          {
            day: "friday",
            startTime: "00:00",
            endTime: "23:59"
          },
          {
            day: "saturday",
            startTime: "00:00",
            endTime: "23:59"
          }
        ],
        approval: "Instant",
        aboutUs: "Was looking for an only zumba fitness class for a long time. This has been super amazing, Bikash sir is splendid & pushes all of us students to the core to do every song & teaches every step diligently. Would definitely recommend joining his class.",
        capacity: "7"
      }
    ],
    creationTimeStamp: "2025-01-03T07:30:00.846Z",
    mapLink: "https://maps.app.goo.gl/uJeP8HXGTAumoERa7"
  }